.management {
  &__header {
    &__title {
      text-align: center;
      text-transform: uppercase;
      font: normal normal bold 26px/30px sans-serif;
      letter-spacing: 0px;
      color: #000000;
      text-transform: uppercase;
      padding-bottom: 50px;
    }

    .custom_border {
      .ant-select-selector {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }


    @media(max-width: 768px) {
      .mb-md-1 {
        margin-bottom: 1rem;
      }
    }

  }

  @media(max-width: 426px) {
    .d-m-none {
      display: none;
    }
  }
}