.punish_container {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  margin: 0;

  &__center_block {
    border-radius: 8px;
    border: 1px solid rgb(241, 241, 241);
    &_title {
      text-align: center;
      padding-bottom: 3rem;
      padding-top: 20px;
      font: normal normal bold 24px/28px sans-serif;
    }

    &_result {
      .row {
        padding-bottom: 20px;
        font: normal normal normal 16px/20px sans-serif;
        row-gap: 16px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .item {
          padding: 10px;
          &:nth-child(2n) {
            background: #fafafa;
          }
        }

        .col-5 {
          font-weight: 600 !important;
        }
      }
    }
  }

  .error {
    text-align: center;
    font-weight: 500;
    color:orangered;
  }
}

@media(min-width: 426px) {
  .custom-width {
    width: 60%;
  }
  .my-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .my-col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333%;
  
  }
}

@media(max-width: 575px) {
  .my-mt-2 {
    margin-top: 1em;
  }
}