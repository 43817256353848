.introduction_container {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;

  * { color: #2A2A2A; text-align: center; }

  &__title {
    font-size: 42px;
    font-weight: bold;
    line-height: 52px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  img {
    width: 100%;
  }

  &__section {
    * { text-align: left; }

    div:first-child {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-top: 40px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  @media(min-width: 554px) {
    &__section {
      max-width: 655px;
    }
  }
}