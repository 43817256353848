@import './main';

.pointer {
  cursor: pointer;
}

.displayNone {
  display: none;
}
.ant-input-number {
  width: 100% !important;
}

.ant-layout,
.ant-layout-footer {
  background-color: #fff;
}

.logo {
  max-width: 70px;
  max-height: 44px;
}

.header {
  .ant-menu-title-content {
    text-align: left;
    font: normal normal 600 16px/20px sans-serif;
    letter-spacing: 0px;
    color: #6b6b6b;
  }

  .ant-menu-item-active.active {
    .ant-menu-title-content {
      color: var(--main-color);
      font: normal normal bold 16px/20px sans-serif;
    }
  }
  @media (max-width: 768px) {
    overflow-x: auto;
    padding: unset;

    &__container {
      width: unset !important;
    }

    &__button {
      margin-left: auto;
    }

    &_content {
      width: unset !important;
      .ant-menu-item-only-child:first-child {
        padding-left: unset;
      }
    }
  }

  @media (max-width: 813px) {
    &__logo {
      display: none;
    }
  }
}
.ant-layout-header,
.ant-menu,
.ant-menu-dark {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: none;
}

.ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-nav-wrap {
  border: none;
  outline: none;
  padding-top: 21.5px;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 9px 12px;

  .ant-tabs-tab-btn {
    text-align: center;
    font: normal normal 600 14px/20px sans-serif;
    letter-spacing: 0px;
    color: #242426;
  }
}

.ant-tabs-tab-active {
  background-color: var(--main-color);
  div {
    color: #ffffff !important;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: unset !important;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset !important;
  }
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid var(--main-color); /* Blue */
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noselect {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.OFF_TABS_ACCREDITATION {
  .custom_tabs {
    padding-top: 2rem;
    .ant-tabs-nav {
      display: none;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}