@import "./../../main";

@keyframes myColor  {
  0% {fill:var(--main-color) }
  50% {fill:var(--sub-color) }
  100% {fill: red }
}

@-moz-keyframes myColor {
  0% {fill:var(--main-color) }
  50% {fill:var(--sub-color) }
  100% {fill: red }
}

@-webkit-keyframes myColor {
  0% {fill:var(--main-color) }
  50% {fill:var(--sub-color) }
  100% {fill: red }
}

@keyframes myTable  {
  0% {
    background-color: var(--sub-color); 
  }
  50% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3F3D56;
    mix-blend-mode: multiply; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
  100% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), var(--main-color);
  }
}

.accreditation{
  width: 100%;
  display: flex;
  justify-content: center;
  &__stt{
    max-width: 60px;
  }

  &__licenplates {
    font-size: 1.5rem;
    letter-spacing: 0.5;
    max-width: 162px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  &__center{
    text-align: center !important;
    width: 180px !important;
  }
  &__circle{
    width: 18px;
    height: 18px;
    background: #D6D6D6 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
    cursor: pointer;
    &:hover{
      background: var(--main-color) 0% 0% no-repeat padding-box;
    }
    &-animation{
      path:nth-child(2){
        animation: myColor 4s;
        -moz-animation:myColor 4s infinite; /* Firefox */
        -webkit-animation:myColor 4s infinite; /* Safari and Chrome */        
      }   
    }
    &-active{   
        path:nth-child(2){
          fill: var(--main-color);
        }
    }
    &-white{
      path:nth-child(2) {
        fill: #d7cbcb !important;
      }
      
    }
    &-blue{
      path:nth-child(2) {
        fill: blue !important;
      }
   
    }
    &-yellow{
      path:nth-child(2) {
        fill: #eded08 !important;
      }
   
     
    }
    &-red{
      path:nth-child(2) {
        fill: red !important;
      }
   
    }
    
  }
  &__img{
    height: 35px !important;
    max-height: 35px !important;
    width: auto;
  }
  &__icon{
    margin-right: 10px;
  }
  &__date{
    margin-bottom: 20px;

    .addIcon {
      svg {
        margin-top: -6px;
      }
    }
  }
  &__parent{
    display: flex;
    align-items: center;
    svg{
      margin-left: 5px;
    }
  }
}

.create_new_customer {
  &_title {
    text-align: center;
    font: normal normal bold 24px/20px sans-serif;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    margin-top: 62px;
  }

  &_body {
    .row {
      margin: 0 !important;
    }
  }
}

.edited-row {
  background-color: var(--sub-color);
  &__add{
    animation: myTable 4s;
    -moz-animation: myTable 4s; 
    -webkit-animation: myTable 4s;   
  }
 
}

.header-button {
  cursor: pointer;
  display: flex;
  margin: 10px 0;
  .anticon {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  span {
    font: normal normal 600 18px/24px sans-serif;
    margin-top: 4px;
    margin-left: 6px;
  }
}

.custom_input {
  text-align: left;
  font: normal normal 600 17px/20px sans-serif !important;
  letter-spacing: 0px;
  color: #242426;
}