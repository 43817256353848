.old_post_component {
    padding-bottom: 20px;
    &__content_left {
        border-radius: 8px;
        resize:inherit;
        width: 100%;
        max-height: 195px;
    }

    @media(max-width: 768px) {
        &__content_right {
            &__title {
                margin-top: 10px;
            }
        }
    }

    &__content_right {
        &__title {
            text-align: left;
            font: normal normal bold 18px/24px sans-serif;
            letter-spacing: 0px;
            color: #242426;
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            text-transform: uppercase;
            
        }

        &__time {
            text-align: left;
            font: normal normal medium 12px/20px sans-serif;
            letter-spacing: 0px;
            color: #B4B4B4;
        }

        &__content {
            text-align: left;
            font: normal normal medium 12px/20px sans-serif;
            letter-spacing: 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 4 !important;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }
    }
}