@import '../../main.scss';
.login {
    margin: 0 12px;
    .login_form {
        display: flex;
        justify-content: center;

        &__title {
            text-align: center;
            font: normal normal bold 24px/20px sans-serif;
            letter-spacing: 0px;
            color: #242426;
            text-transform: uppercase;
            padding-bottom: 75px;
            padding-top: 100px;
        }
    }
}