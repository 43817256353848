@import '../../main.scss';

.schedule_setting {
    width: 100%;
    .schedule_setting__title {
        margin-top: 92px;
        margin-bottom: 49px;
        text-align: center;
        font: normal normal bold 24px/20px sans-serif;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
    }

    &__section_title {
        div {
            label {
                text-align: left;
                font: normal normal bold 16px/20px sans-serif;
                letter-spacing: 0px;
                color: #242426;
            }
        }
    }

    .schedule_setting__body {
        width: 100%;
        justify-content: space-evenly;
        flex-direction: row;
        display: flex;
        
        &__timeline {
            margin-top: 3px;
            text-align: left;
            font: normal normal 600 15px/20px sans-serif;
            letter-spacing: 0px;
            color: #242426;
        }
    }
}