.list_schedules {
    .list_schedules__title {
        text-align: center;
        font: normal normal bold 24px/20px sans-serif;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        padding-top: 58px;
        padding-bottom: 38px;
    }

    &__select {
        padding: 0 !important;

        &_item > * {
            @media(min-width: 1200px) {
                width: 80% !important;
            }
        }
    }

    .handled_customer {
        background-color: #ebffd9;
    }

    .handle_icon {
        height: 17px;
        margin-top: 5px;
    }
}