.list_customers {
  padding-top: 17px;

  .list_customers__title {
    text-align: center;
    font: normal normal bold 26px/30px sans-serif;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 53px;
  }

  &__modal_selectFile {
    text-align: left;
    font: normal normal bold 16px/20px sans-serif;
    letter-spacing: 0px;
    color: #242426;
    padding: 10px;
  }

  &__header {
    &_button {
      display: flex;
      justify-content: space-between;
      padding-bottom: 3rem;
      width: 350px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .no_send {
    svg {
      height: 25px;
      fill: #acacac;
    }
  }

  .send_success {
    svg {
      height: 25px;
      fill: lawngreen;
    }
  }

  .send_fail {
    svg {
      height: 25px;
      fill: orangered;
    }
  }
}

.punish_modal--root {
  .ant-table-cell {
    font-size: 14px !important;
  }
}
