.accreditation_public {
  min-width: 1200px;
  padding-top: 20px;
  flex-direction: row;
  justify-content: space-between;

  &.banner_on {
    display: flex !important;
  }

  &_table_with_banner {
    max-width: calc(100vw - 340px) !important;
  }

  &_banner {
    img {
      width: 160px;
      height: 600px;
    }
  }


}