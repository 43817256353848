@import '../../main.scss';

.booking_main {
    margin: 0 12px;
    .booking-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
            
            .booking-title {
                margin-top: 69px;
                color: $--unnamed-color-24242600;
                text-align: center;
                font: normal normal bold 24px/20px sans-serif;
                letter-spacing: 0px;
                text-transform: uppercase;
                margin-bottom: 68px;
                width: 100%;
            }

            .add-booking-success {
                flex-direction: column;
                width: 100%;
                height: 100%;

                .add-booking-success-icon {
                    width: 200px;
                    height: 200px;
                }

                &_link {
                    color: #007BFF !important;
                    cursor: pointer;
                }

                .ant-card {
                    .ant-card-body {
                        box-shadow: 0px 20px 24px #E4E4E6;

                        div {
                            span {
                                text-align: left;
                                letter-spacing: 0px;
                                color: #242426;
                                font-weight: 500;
                                font-family: sans-serif;
                            }
                        }

                        .hotline {
                            text-align: right;
                            text-decoration: underline;
                            font: normal normal normal 12px/15px sans-serif;
                            letter-spacing: 0px;
                            color: #242426;
                        }
                    }
                }
            }
    }
}